import { render, staticRenderFns } from "./AdsChannelMenu.vue?vue&type=template&id=8c533900&scoped=true&"
import script from "./AdsChannelMenu.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AdsChannelMenu.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AdsChannelMenu.vue?vue&type=style&index=0&id=8c533900&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c533900",
  null
  
)

export default component.exports