import { render, staticRenderFns } from "./DefaultTemplatesList.vue?vue&type=template&id=fb768624&scoped=true&"
import script from "./DefaultTemplatesList.vue?vue&type=script&setup=true&lang=ts&"
export * from "./DefaultTemplatesList.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./DefaultTemplatesList.vue?vue&type=style&index=0&id=fb768624&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb768624",
  null
  
)

export default component.exports