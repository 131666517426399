import { Module, VuexModule, Mutation, Action, getModule, config } from 'vuex-module-decorators';
import _ from 'lodash';
import store from '@/core/store/index.store';
import {AdMedia} from '@/modules/medialibrary/models/video.model';
import {Ad, AdType} from '../models/ads.model';
import { CroppedImage } from '@/modules/medialibrary/models/masterImage.model';
import campaignStore from '@/modules/campaigns/store/campaigns.store';
import { GoogleDisplayGeneratedAd, GoogleDisplayGeneratedAdParams } from '../models/googleAds.model';
import { GoogleSearchAd } from '../models/googleSearch.model';

config.rawError = true;

@Module({
  name: 'adsEditor',
  namespaced: true,
  dynamic: true,
  store,
})
class AdsEditor extends VuexModule {
  public currentAdType: AdType | null = null;
  public media: AdMedia[] = [];
  public editedAd: Ad | null = null;
  public googleDisplayGeneratedEditedAd: GoogleDisplayGeneratedAd | null = null;
  public googleSearchEditedAd: GoogleSearchAd | null = null;
  public adTypeRouteSegment: string | null = null;
  public isAdLoading = false;

  public copy: AdCopy = {
    message: '',
    headline: '',
    displayUrl: '',
    linkDescription: '',
    callToAction: '',
  };

  public googleCopy: GoogleDisplayGeneratedAdParams = {
    type: '',
    subtitle: '',
    title: '',
    callToAction: '',
    tags: [],
    primaryColor: '#05377E',
    logoUrl: '',
  };

  public googleSearchCopy: GoogleSearchCopy = {
    headlines: ['', '', ''],
    descriptions: ['', ''],
  };

  get isEditing(): boolean {
    return !!this.editedAd || !!this.googleDisplayGeneratedEditedAd || !!this.googleSearchEditedAd;
  }

  @Mutation
  public setAdLoading(flag: boolean) {
    this.isAdLoading = flag;
  }


  @Mutation
  public setCopy(copy: AdCopy) {
    this.copy = copy;
  }

  @Mutation
  public setGoogleCopy(copy: GoogleDisplayGeneratedAdParams) {
    this.googleCopy = copy;
  }

  @Mutation
  public setGoogleCopyLogo(logo: any) {
    this.googleCopy = {
      ...this.googleCopy,
      logoUrl: logo.url,
    };
  }

  @Mutation
  public setGoogleSearchCopy(copy: GoogleSearchCopy) {
    this.googleSearchCopy = copy;
  }

  @Mutation public setMedia(media: AdMedia[]) {
    this.media = media;
  }

  @Mutation public setToCroppedUrl(media: CroppedImage) {
    const imageToReplace = this.media.find((mediaItem) => media.id === mediaItem.id);
    // Sometimes call from above(in croppedImages.store in replaceImage action) are still waiting for the backend response while store already changed:
    // When navigating from adsEditor to another page while waiting for the backend response this.media is reset. So
    // imageToReplace might be null
    // and it is not clear whether this last call is necessery or exidental
    if (imageToReplace) {
      imageToReplace!.url = media.url;
    }
  }

  @Mutation public appendMedia(media: AdMedia) {
    this.media.push(media);
  }

  @Mutation public setAdType(adType: AdType) {
    this.currentAdType = adType;
  }

  @Mutation public setEditedAd(editedAd: Ad) {
    this.editedAd = editedAd;
  }

  @Mutation 
  public setGoogleDisplayGeneratedAd(editedAd: GoogleDisplayGeneratedAd) {
    this.googleDisplayGeneratedEditedAd = editedAd;
  }

  @Mutation public setGoogleSearchEditedAd(editedAd: GoogleSearchAd) {
    this.googleSearchEditedAd = editedAd;
  }

  @Mutation
  public setRouteSegment(routeSegment: string) {
    this.adTypeRouteSegment = routeSegment;
  }

  @Action({ commit: 'setCopy'})
  public resetCopy() {
    return {
      message: '',
      headline: '',
      displayUrl: _.get(campaignStore, 'selectedCampaign.displayUrl'),
      linkDescription: '',
      callToAction: '',
    };
  }

  @Action({ commit: 'setGoogleCopy'})
  public resetGoogleCopy() {
    return {
      type: '',
      subtitle: '',
      title: '',
      callToAction: '',
      tags: [],
      primaryColor: '#05377E',
      logoUrl: '',
    };
  }

  @Action({ commit: 'setGoogleSearchCopy'})
  public resetGoogleSearchCopy() {
    return {
      headlines: ['', '', ''],
      descriptions: ['', ''],
    };
  }

  @Action({ commit: 'setCopy'})
  public useCopy(copy: AdCopy) {
    return copy;
  }

  @Action({ commit: 'setCopy'})
  public useCopyFromAd(ad: Ad) {
    const { media , id, ...restAd } = ad;
    const copyOfAd = {
      message: '',
      displayUrl: _.get(campaignStore, 'selectedCampaign.displayUrl'),
      linkDescription: '',
      headline: '',
      callToAction: '',
      ...restAd,
    } ;
    return copyOfAd;
  }

  @Action({ commit: 'setAdType' })
  public changeAdType(adType: AdType) {
    return adType;
  }

  @Action({ commit: 'setEditedAd' })
  public editAd(editedAd: Ad): Ad | null {
    if (!editedAd) {
      return null;
    }

    this.useMedia(editedAd.media);
    this.useCopyFromAd(editedAd);
    return editedAd;
  }

  @Action({ commit: 'setGoogleDisplayGeneratedAd' })
  public editGoogleDisplayGeneratedAd(editedAd: GoogleDisplayGeneratedAd) {
    this.setGoogleCopy(editedAd);
    return editedAd;
  }

  @Action({ commit: 'setGoogleSearchEditedAd' })
  public editGoogleSearchAd(editedAd: GoogleSearchAd) {
    this.copyGoogleSearchAd(editedAd, 'GoogleSearchAd');
    return editedAd;
  }

  @Action
  public useMedia(media: AdMedia[] | AdMedia) {
    if (Array.isArray(media)) {
      media.forEach((mediaItem) => {
        this.context.commit('appendMedia' , mediaItem);
      });
    } else {
      this.context.commit('appendMedia' , media);
    }
  }

  @Action({ commit: 'setMedia'})
  public resetMedia() {
    return [];
  }

  @Action({ commit: 'setEditedAd'})
  public resetEditedAd() {
    return null;
  }

  @Action({ commit: 'setGoogleDisplayGeneratedAd'})
  public resetGoogleDisplayGeneratedAd() {
    return null;
  }

  @Action({ commit: 'setGoogleSearchEditedAd'})
  public resetGoogleSearchEditedAd() {
    return null;
  }

  @Action
  public reset({ resetMedia } = { resetMedia: true }): void {
    if (resetMedia) { this.resetMedia(); }
    this.resetCopy();
    this.resetGoogleCopy();
    this.resetEditedAd();
    this.resetGoogleDisplayGeneratedAd();
    this.resetGoogleSearchCopy();
    this.resetGoogleSearchEditedAd();
  }

  @Action({ commit: 'setMedia'})
  public remove(item: AdMedia) {
    return this.media.filter((media) => {
      return !(media.id === item.id && media.type === item.type);
    });
  }

  @Action
  public copyAd({ ad, newAdType }: { ad: Ad, newAdType: AdType }): void {
    this.changeAdType(newAdType);
    this.useMedia(ad.media);
    this.useCopyFromAd(ad);
  }

  @Action({ commit: 'setRouteSegment' })
  public defineAdTypeRoute(routeSegment: string | null) {
    return routeSegment;
  }

  @Action
  public copyGoogleBannerGeneratedAd(ad: GoogleDisplayGeneratedAd, adType: AdType) {
    this.setAdType(adType);

    const adCopy = { ...ad };
    delete adCopy.id;

    this.setGoogleCopy(adCopy);
  }

  @Action
  public copyGoogleSearchAd(ad: GoogleSearchAd, adType: AdType) {
    this.setAdType(adType);
    
    const { id, ...adCopy } = ad;

    this.setGoogleSearchCopy(adCopy);
  }
}

export interface AdCopy {
  message: string;
  headline: string;
  displayUrl?: string;
  linkDescription: string;
  callToAction: string;
}

export interface GoogleAdCopy {
  type: string;
  subtitle: string;
  title: string;
  callToAction: string;
  tags: string[];
  primaryColor: string;
  logoUrl: string;
}
export interface GoogleSearchCopy {
  headlines: string[];
  descriptions: string[];
}

export default getModule(AdsEditor, store);
