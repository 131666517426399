import _ from 'lodash';
import httpService from '@/core/plugins/httpService';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import {AbstractResource} from '@/core/api/abstract.api';
import { convertTypeForDatabase, convertTypeForFrontend } from '@/core/store/productConfig';
import {CustomTemplate} from '../models/customTemplate.model';
import {FunctionGroup} from '@/modules/campaigns/models/function.group.model';
import {Location} from '@/core/models/location.model';
import {JobProfile} from '@/core/models/jobProfile.model';
import {CampaignGoalValue} from '@/modules/campaigns/models/campaign.goal.model';
import {SocialPage} from '@/modules/accounts/models/socialPage.model';
import { ProductType } from '@/core/models/product.model';
import toastNotificationStore from '@/core/store/toastNotification.store';

export default class CustomTemplateResource extends AbstractResource<CustomTemplate, CustomTemplateResponseJson, CustomTemplateRequestJson> {
  public async findById(id: string): Promise<CustomTemplate> {
    return await httpService.get('/' + this.resourceOwner + '/' + this.resourceName + '/' + id).then(
      (response) => this.hydrate(response.data as CustomTemplateResponseJson));
  }

  public createCustomTemplate(templateType: ProductType): Promise<CustomTemplate> {
    const convertedType = convertTypeForDatabase(templateType);
    return httpService.post(this.collectionEndpoint, {type: convertedType}).then((response) => {
      return this.hydrate(response.data as CustomTemplateResponseJson);
    });
  }

  public deleteCustomTemplate(template: CustomTemplate) {
    return httpService.delete(this.collectionEndpoint + '/' + template.id);
  }
  
  public async updateCustomTemplateFields(template: CustomTemplate): Promise<void> {
    const fields = this.extract(template);
    await httpService.put(this.collectionEndpoint + '/incremental/' + template.id, fields);
  }
  
  public async setFunctionGroup(templateId: string, functionGroup: FunctionGroup) {
    await httpService.put(this.entityEndpoint + '/' + templateId + '/function-group', {functionGroup});
  }

  public async deleteFunctionGroup(templateId: string) {
    await httpService.delete(this.entityEndpoint + '/' + templateId + '/function-group');
  }

  public async setRuntime(templateId: string, runtime: string | number) {
    return await httpService.put(this.entityEndpoint + '/' + templateId + '/runtime', {runtime});
  }

  public async setTargetLanguage(templateId: string, targetLanguage: string | null) {
    return await httpService.put(this.entityEndpoint + '/' + templateId + '/language', {language: targetLanguage});
  }

  public async setBudget(templateId: string, budget: number | undefined) {
    return await httpService.put(this.entityEndpoint + '/' + templateId + '/budget', {budget});
  }

  public async setSocialPage(socialPage: SocialPage, templateId: string) {
    await httpService.put(`${this.entityEndpoint}/${templateId}/social-page`, {pageId: socialPage.id, channel: socialPage.channel});
  }

  public addProfile(data: CustomTemplate, profile: JobProfile): Promise<void> {
    return httpService.post(this.entityEndpoint + '/' + data.id + '/profiles', {
      id: profile.id,
    });
  }

  public deleteProfile(data: CustomTemplate, profile: JobProfile) {
    httpService.delete(this.entityEndpoint + '/' + data.id + '/profiles/' + profile.id);
  }

  public async addLocation(data: CustomTemplate, location: Location) {
    await httpService.post(this.entityEndpoint + '/' + data.id + '/locations', {
      id: location.id, 
      name: location.name,
      type: location.type,
      country: location.country,
    }).then(response => {
      const isLocationNotAllowedInSomeChannels = _.get(response, 'status') === 206;
      if (isLocationNotAllowedInSomeChannels) {
        toastNotificationStore.showToastNotification({
          message: _.get(response, 'data.message'),
          isError: true,
        });
      }
    });
  }

  public deleteLocation(data: CustomTemplate, location: Location) {
    return httpService.delete(this.entityEndpoint + '/' + data.id + '/locations', {
      data: {
        id: location.id, 
        name: location.name,
        type: location.type,
        country: location.country,
      },
    });
  }

  protected extract(template: CustomTemplate): CustomTemplateRequestJson {
    return {
      title: template.title,
      purpose: template.purpose,
      type: convertTypeForDatabase(template.type),
      targetUrl: template.targetUrl,
      goal: template.goal,
      poNumber: template.poNumber,
    };
  }

  protected hydrate(json: CustomTemplateResponseJson): CustomTemplate {
   const template = {
    id: json.id,
    accountId: json.accountId,
    purpose: json.purpose,
    type: convertTypeForFrontend(json.type) as ProductType,
    title: json.title,
    targetUrl: json.targetUrl,
    displayUrl: json.displayUrl,
    runtime: json.runtime,
    locations: json.locations ? json.locations : [],
    profiles: json.profiles ? json.profiles : [],
    functionGroup: json.functionGroup ? this.capitalize(json.functionGroup) as FunctionGroup : '',
    goal: json.goal,
    poNumber: json.poNumber,
    facebookPageId: json.facebookPageId,
    instagramPageId: json.instagramPageId,
    linkedinPageId: json.linkedinPageId,
    customTotalGrossBudget: json.customTotalGrossBudget,
    enabledChannels: json.enabledChannels ? {
      facebook: json.enabledChannels.facebook,
      instagram: json.enabledChannels.instagram,
      linkedIn: json.enabledChannels.linked_in,
      google_display: json.enabledChannels.google_display,
      google_search: json.enabledChannels.google_search,
    } : {
      facebook: true,
      instagram: true,
      google_search: true,
      linkedIn: false,
      google_display: true,
    },
    language: json.language,
   };
   return template;
  }
  
  protected get resourceName() {
    return 'customTemplates';
  }

  protected get resourceOwner() {
    const accountsStore = useAccountsStore();
    return 'accounts/' + accountsStore.selectedAccount!.id;
  }

  private capitalize(name: string): string {
    if (name === 'it') {
      return 'IT';
    }
    if (name === 'charity and fundraising') {
      return 'Charity and Fundraising';
    }
    return name.replace(/(?:^|\s|\/)\S/g, (a) => a.toUpperCase());
  }
}

export interface CustomTemplateResponseJson {
  id: string;
  accountId: string;
  purpose: string;
  type: ProductType;
  title: string;
  targetUrl?: string;
  displayUrl?: string;
  runtime: number;
  locations?: Location[];
  profiles?: JobProfile[];
  functionGroup?: FunctionGroup;
  goal?: CampaignGoalValue;
  poNumber?: string;
  facebookPageId: string | null;
  instagramPageId: string | null;
  linkedinPageId: string | null;
  customTotalGrossBudget?: number;
  enabledChannels: {
    facebook: boolean;
    instagram: boolean;
    linked_in: boolean;
    google_display: boolean;
    google_search: boolean;
  };
  language: string | null;
}

export interface CustomTemplateRequestJson {
  title: string;
  purpose: string;
  type: string;
  targetUrl: string | undefined;
  goal: string | undefined;
  poNumber: string | undefined;
}
