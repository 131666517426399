import { render, staticRenderFns } from "./AdsBottomBar.vue?vue&type=template&id=08e4214d&scoped=true&"
import script from "./AdsBottomBar.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AdsBottomBar.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AdsBottomBar.vue?vue&type=style&index=0&id=08e4214d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e4214d",
  null
  
)

export default component.exports