import Vue from 'vue';
import {Module, VuexModule, Mutation, Action, getModule, config} from 'vuex-module-decorators';
import store from '@/core/store/index.store';
import { Ad, AdType, InputField, GoogleSearchInputField} from '../models/ads.model';
import {AdMedia} from '@/modules/medialibrary/models/video.model';
import {CroppedImage} from '@/modules/medialibrary/models/masterImage.model';
import {GoogleDisplayGeneratedAd, GoogleDisplayGeneratedAdParams} from '../models/googleAds.model';
import { GoogleSearchAd } from '../models/googleSearchAds.model';
import { showNotification } from '@/core/assets/global-utils';

config.rawError = true;

@Module({
  name: 'templateAdsEditor',
  namespaced: true,
  dynamic: true,
  store,
})
class TemplateAdsEditor extends VuexModule {
  public currentAdType: AdType | null = null;
  public media: AdMedia[] = [];
  public editedAd: Ad | null = null;
  public editing: boolean = false; 
  public googleDisplayGeneratedEditedAd: GoogleDisplayGeneratedAd | null = null;
  public googleSearchEditedAd: GoogleSearchAd | null = null;
  public adTypeRouteSegment: string | null = null;
  public cursorInputSelectionStart: number = 0;
  public isAdLoading = false;

  public copy: AdCopy = {
    message: '',
    headline: '',
    linkDescription: '',
    callToAction: '',
  };

  public googleCopy: GoogleDisplayGeneratedAdParams = {
    type: '',
    subtitle: '',
    title: '',
    callToAction: '',
    tags: [],
    primaryColor: '#2E395D',
    logoUrl: '',
  };

  public googleSearchCopy: GoogleSearchCopy = {
    headlines: ['', '', ''],
    descriptions: ['', ''],
  };

  get isEditing(): boolean {
    return !!this.editedAd || !!this.googleSearchEditedAd || !!this.googleDisplayGeneratedEditedAd;
  }

  @Mutation
  public setCursorInputSelectionStart(cursorInputSelectionStart: number) {
    this.cursorInputSelectionStart = cursorInputSelectionStart;
  }

  @Mutation
  public setAdLoading(flag: boolean) {
    this.isAdLoading = flag;
  }

  @Mutation
  public setCopy(copy: AdCopy) {
    this.copy = copy;
  }

  @Mutation
  public setGoogleCopy(copy: GoogleDisplayGeneratedAdParams) {
    this.googleCopy = copy;
  }

  @Mutation
  public setGoogleSearchCopy(copy: GoogleSearchCopy) {
    this.googleSearchCopy = copy;
  }

  @Mutation
  public setMedia(media: AdMedia[]) {
    this.media = media;
  }

  @Mutation
  public setToCroppedUrl(media: CroppedImage) {
    const imageToReplace = this.media.find((mediaItem) => media.id === mediaItem.id);
    imageToReplace!.url = media.url;
  }

  @Mutation
  public appendMedia(media: AdMedia) {
    this.media.push(media);
  }

  @Mutation
  public setAdType(type: AdType) {
    this.currentAdType = type;
  }

  @Mutation
  public setRouteSegment(routeSegment: string) {
    this.adTypeRouteSegment = routeSegment;
  }

  @Mutation
  public setEditedAd(ad: Ad) {
    this.editedAd = ad;
  }

  @Mutation 
  public setGoogleSearchEditedAd(editedAd: GoogleSearchAd) {
    this.googleSearchEditedAd = editedAd;
  }

  @Mutation 
  public setGoogleDisplayGeneratedEditedAd(editedAd: GoogleDisplayGeneratedAd) {
    this.googleDisplayGeneratedEditedAd = editedAd;
  }

  @Mutation
  public setIsEditing(value: boolean) {
    this.editing = value;
  }

  @Action({commit: 'setIsEditing'})
  public updateEdit(value: boolean) {
    return value;
  }

  @Action({commit: 'setCopy'})
  public resetCopy() {
    return {
      message: '',
      headline: '',
      linkDescription: '',
      callToAction: '',
    };
  }

  @Action({ commit: 'setGoogleCopy'})
  public resetGoogleCopy() {
    return {
      type: '',
      subtitle: '',
      title: '',
      callToAction: '',
      tags: [],
      primaryColor: '#2E395D',
      logoUrl: '',
    };
  }
  
  @Action({ commit: 'setGoogleSearchCopy'})
  public resetGoogleSearchCopy() {
    return {
      headlines: ['', '', ''],
      descriptions: ['', ''],
    };
  }

  @Action({commit: 'setCopy'})
  public useCopyFromAd(ad: Ad) {
    const { media , id, ...adRest } = ad;
    const copyOfAd = {
      message: '',
      callToAction: '',
      linkDescription: '',
      headline: '',
      ...adRest,
    };
    return copyOfAd;
  }

  @Action({commit: 'setAdType'})
  public changeAdType(type: AdType | null) {
    return type;
  }

  @Action({commit: 'setEditedAd'})
  public editAd(editedAd: Ad): Ad {
    this.useMedia(editedAd.media);
    this.useCopyFromAd(editedAd);
    return editedAd;
  }

  @Action({ commit: 'setGoogleDisplayGeneratedEditedAd' })
  public editGoogleDisplayGeneratedAd(editedAd: GoogleDisplayGeneratedAd) {
    this.setGoogleCopy(editedAd);
    return editedAd;
  }

  @Action({ commit: 'setGoogleSearchEditedAd' })
  public editGoogleSearchAd(editedAd: GoogleSearchAd) {
    this.copyGoogleSearchAd(editedAd, 'GoogleSearchAd');
    return editedAd;
  }

  @Action({commit: 'setCopy'})
  public addTag(data: {inputField: InputField, tagValue: string, cursorInputPosition: number }) {
    const contentPartBeforeTag = this.copy[data.inputField].substring(0, data.cursorInputPosition);
    const contentPartAfterTag = this.copy[data.inputField].substring(data.cursorInputPosition, this.copy[data.inputField].length);
    const tag = data.tagValue;
    return {...this.copy, [data.inputField]: `${contentPartBeforeTag}${tag}${contentPartAfterTag}`};
  }

  @Action({commit: 'setGoogleSearchCopy'})
  public addGoogleSearchTag(data: { inputField: GoogleSearchInputField, index: number, tagValue: string, cursorInputPosition: number }) {
    const { inputField, index, tagValue, cursorInputPosition } = data;
    const contentPartBeforeTag = this.googleSearchCopy[inputField][index].substring(0, cursorInputPosition);
    const contentPartAfterTag = this.googleSearchCopy[inputField][index].substring(cursorInputPosition, this.googleSearchCopy[inputField][index].length);
    const newContent = `${contentPartBeforeTag}${tagValue}${contentPartAfterTag}`;

    if (newContent.length > 30 && inputField === 'headlines' || newContent.length > 90 && inputField === 'descriptions') {
      showNotification({
        message: 'Amount of characters you are trying to insert is too big',
        isError: true,
      });
      return this.googleSearchCopy;
    }

    Vue.set(this.googleSearchCopy[inputField], index, newContent);

    return this.googleSearchCopy;
  }

  @Action
  public useMedia(media: AdMedia[] | AdMedia) {
    if (Array.isArray(media)) {
      media.forEach((mediaItem) => {
        this.context.commit('appendMedia', mediaItem);
      });
    } else {
      this.context.commit('appendMedia', media);
    }
  }

  @Action({commit: 'setMedia'})
  public resetMedia() {
    return [];
  }

  @Action({commit: 'setEditedAd'})
  public resetEditedAd() {
    return null;
  }

  @Action({ commit: 'setGoogleSearchEditedAd'})
  public resetGoogleSearchEditedAd() {
    return null;
  }

  @Action({ commit: 'setGoogleDisplayGeneratedEditedAd'})
  public resetGoogleDisplayGeneratedEditedAd() {
    return null;
  }

  @Action
  public reset({resetMedia} = {resetMedia: true}): void {
    if (resetMedia) {
      this.resetMedia();
    }
    this.resetGoogleSearchCopy();
    this.resetCopy();
    this.resetGoogleCopy();
    this.resetEditedAd();
    this.resetGoogleSearchEditedAd();
    this.resetGoogleDisplayGeneratedEditedAd();
  }

  @Action({commit: 'setMedia'})
  public remove(item: AdMedia) {
    return this.media.filter((media) => {
      return !(media.id === item.id && media.type === item.type);
    });
  }

  @Action
  public copyAd({ad, newAdType}: {ad: Ad, newAdType: AdType}): void {
    this.changeAdType(newAdType);
    this.useMedia(ad.media);
    this.useCopyFromAd(ad);
  }

  @Action({commit: 'setRouteSegment'})
  public defineAdTypeRoute(routeSegment: string) {
    return routeSegment;
  }

  @Action
  public copyGoogleBannerGeneratedAd(ad: GoogleDisplayGeneratedAd, adType: AdType) {
    this.setAdType(adType);

    const adCopy = { ...ad };
    delete adCopy.id;

    this.setGoogleCopy(adCopy);
  }

  @Action
  public copyGoogleSearchAd(ad: GoogleSearchAd, adType: AdType) {
    this.setAdType(adType);
    
    const { id, ...adCopy } = ad;

    this.setGoogleSearchCopy(adCopy);
  }
}

export interface AdCopy {
  message: string;
  headline: string;
  linkDescription: string;
  callToAction: string;
}

export interface GoogleSearchCopy {
  headlines: string[];
  descriptions: string[];
}

export default getModule(TemplateAdsEditor, store);
