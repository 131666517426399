import { render, staticRenderFns } from "./LeadformAccordion.vue?vue&type=template&id=075fbb04&scoped=true&"
import script from "./LeadformAccordion.vue?vue&type=script&setup=true&lang=ts&"
export * from "./LeadformAccordion.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./LeadformAccordion.vue?vue&type=style&index=0&id=075fbb04&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "075fbb04",
  null
  
)

export default component.exports