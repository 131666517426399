import { Module, VuexModule, Mutation, Action, getModule, config } from 'vuex-module-decorators';
import store from '@/core/store/index.store';
import { MasterImage, MediaFilter, UploadState } from '@/modules/medialibrary/models/masterImage.model';
import { useCroppedImagesStore } from './croppedImages.store';
import { AdMedia, Media, Video, InvalidMedia } from '@/modules/medialibrary/models/video.model';
import toastNotificationStore from '@/core/store/toastNotification.store';
import adsEditorStore from '@/modules/ads/store/adsEditor.store';
import { defineStore } from 'pinia';
import { useMasterImageStore } from '@/modules/medialibrary/store/masterImages.store';
import { useVideosStore } from '@/modules/medialibrary/store/videos.store';
import templateAdsEditorStore from '@/modules/template-ads/store/templateAdsEditor.store';
import { adsConfig, getAdConfig, getAdConfigByRoute } from '@/modules/ads';
import { AdValidation } from '@/modules/ads/models/ads.model';
import { isValidVideoRatio, isValidVideoMinWidth } from '@/modules/ads/helpers';

import { unixDate } from '@/core/plugins/date';
import { getValidationByAdType } from '@/core/validations/ads/channels';

interface MediaModalState {
  isActive: boolean;
  mediaIndex: number;
}
const fullFilterOptionsList: MediaFilter[] = ['all', 'image', 'video', 'logo'];

interface State {
  availableFilterOptions: MediaFilter[];
  isVisitingFromAds: boolean;
  placeholderCount: number;
  searchString: string;
  uploadState: UploadState;
  selectedMedia: Media[];
  mediaFilter: MediaFilter;
  isComingFromGoogleAd: boolean;
  isForLeadform: boolean;
  callRoute: string;
  mediaDetailModal: MediaModalState;
}

export const useMediaLibraryStore = defineStore('mediaLibraryStore', {
  state: (): State => {
    return { 
      availableFilterOptions: fullFilterOptionsList,
      isVisitingFromAds: false,
      placeholderCount:  0,
      searchString: '',
      uploadState: 'idle',
      selectedMedia: [],
      mediaFilter: 'all',
      isComingFromGoogleAd: false,
      isForLeadform: false,
      callRoute: '',
      mediaDetailModal:{
        isActive: false,
        mediaIndex: 0,
      }
    }
  },
  getters: {
    currentValidations(state): AdValidation | null {
      const isMediaLibraryPath = state.callRoute.includes('/presets/media-library');
      if (isMediaLibraryPath) {
        return null;
      }
      const adStore = state.callRoute.includes('/templates') ? templateAdsEditorStore : adsEditorStore;
      return getValidationByAdType(adStore.currentAdType);
    },
    isSingleSelection(): boolean {
      return adsEditorStore.currentAdType
        ? adsEditorStore.isEditing && getAdConfig(adsEditorStore.currentAdType).singleMediaAd
        : false;
    },
    isSingleSelectionTemplate(): boolean {
      return templateAdsEditorStore.currentAdType
        ? templateAdsEditorStore.isEditing && getAdConfig(templateAdsEditorStore.currentAdType).singleMediaAd
        : false;
    },
    filteredMedia(state): Media[] {
      const masterImageStore = useMasterImageStore();
      const videosStore = useVideosStore();
      const media: Media[] = [...masterImageStore.validImages, ...videosStore.validVideos, ...masterImageStore.logos];
      return media.filter((mediaItem) => {
        return mediaItem.title.toLowerCase().includes(state.searchString.toLowerCase()) &&
          ( mediaItem.type === state.mediaFilter || state.mediaFilter === 'all' );
      }).sort((a, b) => {
        return unixDate(b.createdAt) - unixDate(a.createdAt);
      });
    },
    filteredInvalidMedia(state): InvalidMedia[] {
      const masterImageStore = useMasterImageStore();
      const videosStore = useVideosStore();
      const invalidMedia: InvalidMedia[] = [...masterImageStore.invalidImages, ...videosStore.invalidVideos];
      return invalidMedia.filter((mediaItem) => {
        return mediaItem.title.toLowerCase().includes(state.searchString.toLowerCase()) &&
          ( mediaItem.type === state.mediaFilter || state.mediaFilter === 'all' );
      }).sort((a, b) => {
        return unixDate(b.createdAt) - unixDate(a.createdAt);
      });
    },
  
  },
  actions: {
    setIsForLeadform(value: boolean) {
      this.isForLeadform = value; 
    },
    setIsComingFromGoogleAd(value: boolean) {
      this.isComingFromGoogleAd = value; 
    },
    setFilter(filter: MediaFilter) {
      this.mediaFilter = filter;
    },
    setOptions(options: Record<string, any>) {
      this.callRoute = options.callRoute;
    },
    fetchMediaLibraryResources() {
      const masterImageStore = useMasterImageStore();
      const videosStore = useVideosStore();
      
      masterImageStore.getImages();
      videosStore.getVideos();
      masterImageStore.getLogos();
    },
    allowSingleFilter(filter: MediaFilter) {
      this.setFilter(filter);
      this.availableFilterOptions = [filter]
    },
    resetFilter() {
      this.setFilter('all');
      this.availableFilterOptions = fullFilterOptionsList
    },
    updateUploadState(uploadState: UploadState): void {
      this.uploadState = uploadState;
    },
    async createMedia(files: FileList): Promise<Array<Media | null>> {
      const masterImageStore = useMasterImageStore();
      const videosStore = useVideosStore();
      
      this.updateUploadState('loading');
      let success: boolean = true;
      const promises: Array<Promise<Media | null>> = [];
      for (const file of files) {
        const isValidImage = await masterImageStore.validateImage(file);
        const isValidVideo = await videosStore.validateVideo(file);
        if (isValidImage) {
           promises.push(masterImageStore.createImage(file));
        } else if (isValidVideo) {
          promises.push(videosStore.createVideo(file));
        } else {
          toastNotificationStore.showToastNotification({
            message: `${file.name} has an invalid image type`,
            isError: true,
          });
          success = false;
        }
      }
      return Promise.all(promises).then((results) => {
        const isAnyFailed = results.some((res) => res === null) || !success;
        this.updateUploadState(!isAnyFailed ? 'succeeded' : 'failed');  
        return results;
      });
    },
    async createLogo(files: FileList) {
      const masterImageStore = useMasterImageStore();
    
      this.updateUploadState('loading');
      if (!this.isComingFromGoogleAd && this.mediaFilter === 'logo') {
        let success: boolean = true;
        const promises: Array<Promise<Media>> = [];
        for (const file of files) {
          if (await masterImageStore.validateLogo(file)) {
            promises.push(masterImageStore.createLogo(file));
          } else {
            toastNotificationStore.showToastNotification({
              message: `${file.name} has an invalid image type`,
              isError: true,
            });
            success = false;
          }
        }
        return Promise.all(promises).then((results) => {
          this.updateUploadState(success ? 'succeeded' : 'failed');
          return results;
        });
      } else if (this.isComingFromGoogleAd) {
        if (files.length > 1) {
          toastNotificationStore.showToastNotification({
            message: `Can only upload one logo at a time.`,
            isError: true,
            isSuccess: false,
            isWarning: false,
          });
          return this.updateUploadState('failed');
        }
        if (await masterImageStore.validateLogo(files[0])) {
          await masterImageStore.createLogo(files[0]);
        } else {
          toastNotificationStore.showToastNotification({
            message: `${files[0].name} has an invalid image type`,
            isError: true,
          });
          return this.updateUploadState('failed');
        }
        this.setIsComingFromGoogleAd(false);
        this.updateUploadState('succeeded');
      }
    },
    async prepareMediaObject(media: Media[]): Promise<AdMedia[]> {
      const videosStore = useVideosStore();
      const croppedImageStore = useCroppedImagesStore();
      const promises = media.map((mediaItem) => {
        if (mediaItem.type === 'video') {
          return videosStore.createAdMediaFromVideo(mediaItem as Video);
        } else {
          return croppedImageStore.createCroppedImageFromMasterImage(mediaItem).then(croppedImage => {
            return {
              ...croppedImage,
              previewUrl: croppedImage?.thumbNailUrl,
            };
          });
        }
      });
      return await Promise.all(promises);
    },
    async deleteMedia(media: Media[]): Promise<Array<Video | MasterImage>> {
      const masterImageStore = useMasterImageStore();
      const videosStore = useVideosStore();
      
      const promises = media.map((mediaItem) => {
        if (mediaItem.type === 'video') {
          return videosStore.deleteVideo(mediaItem as Video);
        } 
        return masterImageStore.delete(mediaItem);
      });
      return await Promise.all(promises);
    },
    renameMedia(media: Media): Promise<void> {
      const masterImageStore = useMasterImageStore();
      const videosStore = useVideosStore();
      
      if (media.type === 'video') {
        return videosStore.renameVideo(media as Video);
      }
      return masterImageStore.renameImage(media);
    },
    incrementPlaceholderCount() {
      this.placeholderCount += 1;
    },
    decrementPlaceholderCount() {
      this.placeholderCount -= 1;
    },
    resetSelectedMedia() {
      this.selectedMedia = [];
    },
    removeSelectedMedia(removingMedia: Media) {
      this.selectedMedia = this.selectedMedia.filter((media) => media.url !== removingMedia.url);
    },
    deselectMedia(media: Media) {
      this.removeSelectedMedia(media);
    },
    selectMedia(media: Media) {
      if (this.isSingleSelection) {
        this.resetSelectedMedia();
      }
      this.selectedMedia.push(media)
    },
    filter(filter: MediaFilter) {
      this.setFilter(filter);
    },
    updateVisitingFromAds(visitingFromAds: boolean) {
      this.isVisitingFromAds = visitingFromAds;
    },
    search(searchString: string) {
      this.searchString = searchString;
    },
    clearSearch() {
      this.searchString = '';
    },
    openMediaModal(media: Media) {
      const mediaIndex = this.filteredMedia.indexOf(media);
      this.mediaDetailModal = { isActive: true, mediaIndex};
    },
    closeMediaModal() {  
      this.mediaDetailModal = { ...this.mediaDetailModal, isActive: false }; 
    },
    openMediaInModal(mediaIndex: number) { 
      this.mediaDetailModal = { mediaIndex, isActive: true }; 
    },
    validateMedia(media: Media) {
      // this.currentValidations is null, in case we use medialibrary in settings.
      // So that it checks all validations and if at least on is true or false
      // it notifies the user 
      if (!this.currentValidations) {
        const isSomeValid = adsConfig.some((ad) => {
          const currentValidations =  getAdConfigByRoute(ad.routeSegment).validations;
          const { imageMinWidth, videoRatio, videoMinWidth } = currentValidations!;
          if (!media) {
            return false;
          }
          if (media.type === 'image') {
            return imageMinWidth! <= media.width;
          }
          if (media.type === 'video') {
            return isValidVideoRatio(media, videoRatio) && isValidVideoMinWidth(media, videoMinWidth);
          }
        });
  
        return isSomeValid ?  
        toastNotificationStore.showToastNotification({
          message: `Uploaded media is only valid for some ad types`,
          isSuccess: true,
        }) :
        toastNotificationStore.showToastNotification({
          message: `Uploaded media is not valid for all ad types`,
          isError: true,
        });
      }
  
      const { imageMinWidth, videoRatio, videoMinWidth } = this.currentValidations!;
  
      if (media.type === 'image') {
        return imageMinWidth! <= media.width ? this.selectMedia(media) : toastNotificationStore.showToastNotification({
          message: `${media.fileName} requires a minimum width of ${imageMinWidth}px`,
          isError: true,
        });
      } else if (media.type === 'video') {
        const ratios = (this.currentValidations!.videoRatio || []).map(({x, y}) => {
          return x + ':' + y;
        }).join(' or ');
  
        if (!isValidVideoRatio(media, videoRatio) && !isValidVideoMinWidth(media, videoMinWidth)) {
            return toastNotificationStore.showToastNotification({
              message: `${media.fileName} requires an aspect ratio of ${ratios} and a minimum width of ${videoMinWidth}`,
              isError: true,
            }); 
        } else if (!isValidVideoRatio(media, videoRatio)) {
            return toastNotificationStore.showToastNotification({
              message: `${media.fileName} requires an aspect ratio of ${ratios}`,
              isError: true,
            }); 
        } else if (!isValidVideoMinWidth(media, videoMinWidth)) {
            return toastNotificationStore.showToastNotification({
              message: `${media.fileName} requires a minimum width of ${videoMinWidth}`,
              isError: true,
            }); 
        }
      }
  
      this.selectMedia(media);
    }
  },
});